<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="mobile" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户手机号')}}<template slot="title">{{$t('')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.mobile" :maxLength="15" :placeholder="$t('通用.输入.请输入')+$t('手机号')" disabled="true"/>
      </a-form-model-item>

      <a-form-model-item prop="nickName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户昵称')}}<template slot="title">{{$t('用户信息.昵称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.nickName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('用户信息.昵称')" disabled="true"/>
      </a-form-model-item>
      <a-form-model-item prop="realName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户信息.真实姓名')}}<template slot="title">{{$t('用户信息.真实姓名')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.realName" :maxLength="50"  disabled="true"/>
      </a-form-model-item>

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="审核状态">
        <a-select v-model="form.certificationStatus"  disabled="true">
          <a-select-option value="1">未认证</a-select-option>
          <a-select-option value="2">已认证</a-select-option>
          <a-select-option value="3">审核中</a-select-option>
          <a-select-option value="4">审核驳回</a-select-option>
        </a-select>
        <!--<a-input placeholder="请输入虚拟或实物 (0-积分 1-优惠券 2-外部卡券 3-实物 4-空）" v-decorator="['prizeType', {}]" />-->
      </a-form-item>

      <a-form-model-item prop="idCard" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户信息.身份证号')}}<template slot="title">{{$t('用户信息.身份证号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.idCard" :maxLength="50" disabled="true"/>
      </a-form-model-item>

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="身份证正面">
          <div class="imageDivBorder">
            <img :src="form.idCardFront" @click="handleRecordPreview(form.idCardFront)">
          </div>
      </a-form-item>

<!--      <a-form-model-item prop="idCardFront" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户信息.身份证正面')}}<template slot="title">{{$t('用户信息.身份证正面')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <file-upload v-model="form.idCardFront" type="image"></file-upload>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="idCardContrary" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户信息.身份证背面')}}<template slot="title">{{$t('用户信息.身份证背面')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <file-upload v-model="form.idCardContrary" type="image"></file-upload>-->
<!--      </a-form-model-item>-->
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="身份证背面">
        <div class="imageDivBorder">
          <img :src="form.idCardContrary" @click="handleRecordPreview(form.idCardContrary)">
        </div>
      </a-form-item>
      <a-form-model-item prop="certificationOpinion" >
        <span slot="label" >
          <a-tooltip>
            {{$t('实名认证审核意见')}}<template slot="title">{{$t('实名认证审核意见')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.certificationOpinion" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="authentication(2,form.id,form.certificationOpinion)" v-if="form.certificationStatus==3">
            {{$t('审核通过')}}
          </a-button>

          <a-button type="debug" :loading="submitLoading" @click="authentication(4,form.id,form.certificationOpinion)" v-if="form.certificationStatus==3">
            {{$t('审核驳回')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {authentication, getUser, updateUser} from '@/api/biz/user'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      authenticationParam:null,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        mobile: null,

        avatar: null,

        nickName: null,

        realName: null,

        certificationStatus: '0',

        idCard: null,

        idCardFront: null,

        idCardContrary: null,

        certificationOpinion: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile: [
          { required: true, message: this.$t('用户信息.用户名/手机号，用于登录')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: this.$t('用户信息.头像链接')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: this.$t('用户信息.昵称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    authentication(status,id,certificationOpinion){
      console.log("aaaaaa",status)
      console.log("bbbbbbbb",id)
      console.log("ccccccc",certificationOpinion)
      authentication({"id":id,"status":status,"certificationOpinion":certificationOpinion}).then(response => {
        this.open = false
        this.$emit('ok')
      })
    },

    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        mobile: null,
        avatar: null,
        nickName: null,
        realName: null,
        certificationStatus: '0',
        idCard: null,
        idCardFront: null,
        idCardContrary: null,
        certificationOpinion: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateUser(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addUser(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/** Button按钮间距 */
.ant-btn {
  margin-left: 30px;
  margin-bottom: 30px;
  float: right;
}
.imageDiv{
  float: left;width:150px;height:200px;margin-right: 10px;margin: 0 8px 8px 0;
}
.imageDiv .imageDivBorder{
  width: 100%;height: 100%;position: relative;padding: 8px;border: 1px solid #d9d9d9;border-radius: 4px;
}
.imageDiv .imageDivBorder img{
  width: 100%;
  height: 100%;
}
</style>